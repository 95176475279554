import Card, { CardProps } from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import React from "react";
import { prefixClassName } from "@smartrr/shared/components/primitives/index";
import { ICustomerPortalTheme } from "../../entities/CustomerPortalTheme";
import { Theme } from "@mui/material";

interface AquariusCardProps extends CardProps {
  theme?: Theme & ICustomerPortalTheme;
  contained?: boolean;
}

const CardWrapper = styled((props: AquariusCardProps) => <Card {...props} />)`
  &.MuiCard-root {
    background-color: ${props => props.theme.modernCardBackground};
    border-color: ${props => props.theme.modernCardBorderColor};
    border-radius: ${props => props.theme.modernCardRadius}px;
    border-style: solid;

    border-width: ${props => props.theme.modernCardBorder}px;
    box-shadow: none;
    padding: ${({ contained }) => (contained ? "20px" : "0px")};
  }
`;

export const AquariusCard = ({ contained = true, ...props }: AquariusCardProps): JSX.Element => {
  return (
    <CardWrapper className={prefixClassName("card")} contained={contained} {...props}>
      {props.children}
    </CardWrapper>
  );
};
