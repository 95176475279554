import { styled } from "@mui/material/styles";
import React from "react";

import { prefixClassName } from "@smartrr/shared/components/primitives";
import { Theme } from "@mui/material";
import { ICustomerPortalTheme } from "../../entities/CustomerPortalTheme";

const SpinnerWrapper = styled("div")<IDotSpinnerProps>`
  align-items: center;
  display: flex;
  height: ${props => props.size}px;
  justify-content: flex-start;
  position: relative;
  width: ${props => props.size}px;

  .dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }

  .dot::before {
    content: "";
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: ${props => props.color || props.theme.modernIconsColor};
    transform: scale(0);
    opacity: 0.5;
    animation: pulse calc(${props => props.speed}s * 1.111) ease-in-out infinite;
  }

  .dot:nth-of-type(2) {
    transform: rotate(45deg);
  }

  .dot:nth-of-type(2)::before {
    animation-delay: calc(${props => props.speed}s * -0.875);
  }

  .dot:nth-of-type(3) {
    transform: rotate(90deg);
  }

  .dot:nth-of-type(3)::before {
    animation-delay: calc(${props => props.speed}s * -0.75);
  }

  .dot:nth-of-type(4) {
    transform: rotate(135deg);
  }

  .dot:nth-of-type(4)::before {
    animation-delay: calc(${props => props.speed}s * -0.625);
  }

  .dot:nth-of-type(5) {
    transform: rotate(180deg);
  }

  .dot:nth-of-type(5)::before {
    animation-delay: calc(${props => props.speed}s * -0.5);
  }

  .dot:nth-of-type(6) {
    transform: rotate(225deg);
  }

  .dot:nth-of-type(6)::before {
    animation-delay: calc(${props => props.speed}s * -0.375);
  }

  .dot:nth-of-type(7) {
    transform: rotate(270deg);
  }

  .dot:nth-of-type(7)::before {
    animation-delay: calc(${props => props.speed}s * -0.25);
  }

  .dot:nth-of-type(8) {
    transform: rotate(315deg);
  }

  .dot:nth-of-type(8)::before {
    animation-delay: calc(${props => props.speed}s * -0.125);
  }

  @keyframes pulse {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

interface IDotSpinnerProps {
  size?: number;
  color?: string;
  speed?: number;
  theme?: Theme & ICustomerPortalTheme;
}

export const DotSpinner = ({ size = 40, color, speed = 0.9, ...props }: IDotSpinnerProps): React.JSX.Element => {
  return (
    <SpinnerWrapper
      data-testid="dot-spinner"
      size={size}
      color={color}
      speed={speed}
      className={prefixClassName("dot-spinner")}
      {...props}
    >
      <div className={"dot"} />
      <div className={"dot"} />
      <div className={"dot"} />
      <div className={"dot"} />
      <div className={"dot"} />
      <div className={"dot"} />
      <div className={"dot"} />
      <div className={"dot"} />
    </SpinnerWrapper>
  );
};
